import React from "react"
import { graphql, Link as RouterLink } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import SayHi from "../components/SayHi"

import { Heading1, Paragraph, Heading2,TextEm,TextSmall } from "../components/Typography"
import { Box } from "../components/Box"
import List from "../components/List/List"
import Link from "../components/Link"
import { Separator } from "../components/Separator"


const PinPic = styled.div`
  position:absolute;
  z-index:1;
  text-align:center;
  @media (max-width: 414px) {
    position:relative;
    top:0px;
    right:0px;
    margin:0 auto;
  }
`
const PinLife = styled(PinPic)`
    right:30px;
    top:140px;
    width:300px;
`
const PinStudent = styled(PinPic)`
    right:-120px;
    top:-10px;
    width:150px;
    @media (max-width: 414px) {
      width:100px;
    }
`
const PinWork = styled(PinPic)`
    right:-30px;
    bottom:10px;
    width:250px;
`
const AboutMe = ({ data }) => (
  <Layout>
    <SEO title="关于" />
  
    <Box maxWidth="640px" marginTop={20}>
    
      <Heading1> <span role="img">👋</span> 我的故事</Heading1>
      
      <Paragraph>
      大家好，我叫阿义，1987年出生于广东,汕头，在深圳长大。
      </Paragraph>
      
      <Box marginTop={20} element="section">
      <Heading2><span role="img">🎓</span> 学生</Heading2>
      <Paragraph>
      我的学生生涯和大部分人一样，1999年第一次互联网热潮兴起时，我们家置办了一台可用 ADSL 上网<Link href="https://wenku.baidu.com/view/3f8c77d676a20029bd642d14.html"  underlined target="_blank"
>联想同禧</Link>电脑，小学六年级我开始制作班级主页和各种壁纸站，2002年我在一家香港公司打暑期工，为香港本地的商家制作网站，与其他同学做服务生、派传单相比，15岁我体会到父亲说的人要有<TextEm>“一技之长”</TextEm>的道理。
      
      </Paragraph>
      <Paragraph>
      2006年高考顺利考上<Link href="https://www.gdufs.edu.cn/"  underlined target="_blank"
>广东外语外贸大学</Link>电子商务专业，我和同学一起组建<Link href="http://i-wui.com/"  underlined target="_blank"
>艾维工作室</Link>，以工作室的名义，我们接了不少单子，亦因此认识到许多志同道合的的伙伴。
      </Paragraph>
      <PinStudent> 
      <Img fluid={data.student.childImageSharp.fluid}  alt="student"/>
      </PinStudent>
      </Box>
      <Box marginTop={20} element="section">    
      <Heading2><span role="img">👨‍💻</span> 职业</Heading2>
      <Paragraph>
        2009年，我正式开始实习，正好赶上互联网的第二次热潮，BAT 都在大规模招聘前端开发，我先后在杭州阿里旺旺的 UED 和淘宝 UED 实习，那是前端开发最美好的 Web 2.0 时代，各种新的交互方式出现和挑战极限的性能优化方法。
      </Paragraph>
      <Paragraph>
        毕业后，我回到深圳，加入了腾讯 <Link href="https://isux.tencent.com/"  underlined target="_blank"
>ISUX</Link> 的前身 ISD Webteam，负责 QQ 会员官网的业务，每天重复的螺丝钉工作，让我感觉不到成就感，2011年，我跟随<Link href="http://twinsenliang.net/"  underlined target="_blank"
>导师 Leader</Link> 离职创业，创办了一家定位模糊的公司，为了生存，虽然公司只有6人，但我们做过外包业务、模拟基金、游戏代练等等毫无关联性的业务，创业的结果是失败的，但这段经历让我清晰地认识到自己的不足，明白<TextEm>“知易行难”</TextEm>的道理。
      </Paragraph>
      <Paragraph>
        2012年，我重新回到腾讯，到了 < Link href="http://cdc.tencent.com/"  underlined target="_blank"
>CDC</Link> ，先后负责腾讯视频、SOSO的网页重构工作，我没有太多机会感受部门辉煌的历史，在我加入后不久部门就被< Link href="https://www.zhihu.com/question/21065990"  underlined target="_blank"
>分拆</Link>，有段时间身边同事大规模地离职，我理解平台变化带来的认同感落差和上升通道的变窄，但前面创业的经历也让我清楚的知道属于自己和属于<TextEm>“平台光环”</TextEm>的区别。
      </Paragraph>
      <Paragraph>
      2013年到2015年，整个部门在动荡中寻找机会，在管理层的带领下，我们开始把过去的专业经验转化为平台工具，我所在的设计研发中心，也由过去 UED 式的对外人力支持，转型为对用户研究和体验设计两大平台的支持，我负责构建公司的<Link href="https://dcloud.qq.com/"  underlined target="_blank"
>设计管理平台</Link>的体系，包括设计流程、设计资源、设计规范等几个工具，这时候我的职业发展有两个比较大的变化，一是从公司一线 ToC 业务到内部服务的开发，二是从单一前端工种到负责整个产品的研发，在技能和心态上我适应得不错，总结职业前几年，我意识到自己的<TextEm>“核心能力”</TextEm>在快速学习和适应环境，这帮助我在新领域能快速地入门和成长，在团队中成为多面手，在2015年我被任命为小组的 Team Leader。
      </Paragraph>
      <Paragraph>
      2016年，部门负责公司投资并购流程信息化系统的设计，系统原先由外部服务商负责开发，但因种种原因，实施过程并不顺利，基于投资领域的专业性和数据的安全性，我们意识到需要有自己的研发团队才能支撑系统。因为<Link href="http://investment.tencent.com/"  underlined target="_blank"
>腾讯投资</Link>和 CDC 两个大平台的影响力，我们很快<Link href="https://weibo.com/ttarticle/p/show?id=2309404347627255807850"  underlined target="_blank"
>招聘</Link>好了团队，系统也在跌跌撞撞的过程中逐渐支撑起腾讯的所有投资流程。
      </Paragraph>
      <Paragraph>
       2018年开年，在部门年会间隙，部门负责人拉上我们几个 Leader 介绍<Link href="https://cloud.tencent.com/developer/news/220701"  underlined target="_blank"
>数字广东</Link>项目，大意项目很重要而且迫切需要我们支持，隔天我和设计师先行到广州了解完项目情况，便安排团队启程到广州，项目的情况有些类似建筑行业的总包和分包制度，数字广东公司负责协调数十个服务开发商开发，因为规模大，时间短，这次项目是对我个人和团队在专业和项目管理能力的挑战，这一年发生了很多故事，不同人也有不同的感悟：
        </Paragraph>
        <Box>
          <List title="他们的故事">
            <List.Item
              subtitle="by Enya"
              link="https://zhuanlan.zhihu.com/p/54819229"
            >
              一个小程序，能装下这个时代的多少爱与自由
            </List.Item>
            <List.Item
              subtitle="by 刘帅"
              link="https://v.qq.com/x/page/j3007ls88f4.html"
            >
              墨夫当关
            </List.Item>
            <List.Item
              subtitle="by 魏仁佳"
              link="https://www.wxwenku.com/d/200409876"
            >
              设计如何创造幸福感——数字政府服务设计全新实践
            </List.Item>
          </List>
          <Separator />
          <PinWork> <Img fluid={data.work.childImageSharp.fluid}  alt="work"/>
      </PinWork>
        </Box>
        <Paragraph>
      在这一年中，我逐渐意识自己在前端技能上的开始有所落后，虽然项目成功落地，但无论在技术栈上、工具上、架构上，我的专业技能停留在刀耕火种的时代上。

      </Paragraph>
      <Paragraph>
      最近一年，关于<Link href="https://www.zhihu.com/question/264237428"  underlined target="_blank"
>程序员的中年危机</Link>让我思考了很多，身边也有不少前辈开始转型，某次参加公司技术管理的培训，有人提问，到底技术管理需不需要一线的代码能力，那天讲师的回答“团队需要你到哪就到哪”，这番回答对我触动很大，并不是我能做什么，我不能做什么，而是团队最需要什么，不让自己成为<TextEm>“团队天花板”</TextEm>，是我重新开始博客和写下以上职业总结的原因。
      </Paragraph>
      <Paragraph>
      <Separator />
        <TextSmall>以上，写于2020年2月26日。</TextSmall>
      </Paragraph>
      
      </Box>
    
      <Box marginTop={20} element="section">
        <Heading2><span role="img">🏖</span> 生活</Heading2>
        <Paragraph>
          我的生活比较枯燥，没有特别的爱好和擅长，但每当投入到某种爱好，都会非常长期的热爱，例如：
        </Paragraph>
        
        <Box>
          <List title="兴趣列表">
            <List.Item
              subtitle="40季，20年"
              link="https://www.cbs.com/shows/survivor/"
            >
              美剧：《Survivor》
            </List.Item>
            <List.Item
              subtitle="2002年至今"
              link="https://www.nba.com/rockets/"
            >
              篮球：Houston Rockets 球迷
            </List.Item>
            <List.Item
              subtitle="8赛季 20星王者段位"
              link="https://pvp.qq.com/"
            >
              游戏：《魔兽世界》《王者荣耀》
            </List.Item>
          </List>
          <Separator />
         
        </Box>
        <PinLife>
        <Img fluid={data.life.childImageSharp.fluid}  alt="life"/>
          </PinLife>
      </Box>
      
      
        
     
      <Box element="section" marginTop={20} marginBottom={32}>
        <SayHi />
      </Box>
    </Box>
  </Layout>
)
export const query = graphql`
  query {
    student: file(relativePath: { eq: "images/about/student.png" }) {
      childImageSharp {
        fluid(maxWidth: 150,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    life: file(relativePath: { eq: "images/about/life.png" }) {
      childImageSharp {
        fluid(maxWidth: 250,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    work: file(relativePath: { eq: "images/about/work.png" }) {
      childImageSharp {
        fluid(maxWidth: 300,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
export default AboutMe
