import React from "react"

import Link from "../Link"
import { Box } from "../Box"
import styled from "styled-components"

const IconListWrapper = styled.ul`
  margin-top:20px;
  > li {
    display:inline-block;
    margin:0 5px 0 0;
  }

  svg {
    width:32px;
  }
`

const IconList = ({ children, ...props }) => (
  <IconListWrapper
    {...props}
  >
    {children}
  </IconListWrapper>
)

const SayHi = () => (
  <Box
    maxWidth="640px"
    fontFamily="default"
    fontSize="2xl"
    fontWeight={400}
    lineHeight={1.5}
    textColor="white"
  >
   你可以在以下平台找到我，如有需要，可{" "}<Link
      aria-label="Send an E-Mail to mailto:avenirzheng@gmail.com"
      href="mailto:avenirzheng@gmail.com"
      underlined
    >邮件</Link>{" "}联系！
    
    <IconList>
				<li><a href="http://weibo.com/avenirzheng/" target="_blank" title="Weibo">
						<svg aria-hidden="true" viewBox="0 0 94 94" fill="var(--textLink)">
<g>
	<g>
		<path d="M40.896,56.609c-0.762-0.303-1.719,0.062-2.162,0.812c-0.434,0.754-0.195,1.612,0.568,1.928
			c0.775,0.318,1.768-0.051,2.217-0.818C41.947,57.762,41.67,56.895,40.896,56.609z" />
		<path d="M36.002,58.639c-1.986-0.832-4.551,0.025-5.777,1.947c-1.24,1.928-0.658,4.229,1.313,5.123
			c2.002,0.914,4.656,0.046,5.896-1.932C38.652,61.775,38.008,59.496,36.002,58.639z"/>
		<path d="M89,0H5C2.238,0,0,2.238,0,5v84c0,2.762,2.238,5,5,5h84c2.762,0,5-2.238,5-5V5C94,2.238,91.762,0,89,0z M40.77,78.368
			c-16.07,0-32.494-7.784-32.494-20.597c0-6.692,4.242-14.438,11.547-21.744c9.758-9.752,21.135-14.193,25.41-9.914
			c1.889,1.885,2.07,5.152,0.857,9.05c-0.633,1.965,1.844,0.879,1.844,0.881c7.885-3.301,14.765-3.495,17.279,0.097
			c1.34,1.914,1.213,4.6-0.023,7.712c-0.571,1.434,0.179,1.654,1.269,1.981c4.442,1.378,9.388,4.708,9.388,10.578
			C75.844,66.131,61.834,78.368,40.77,78.368z M69.855,37.646c0.521-1.606,0.194-3.436-1.019-4.779
			c-1.209-1.341-2.998-1.851-4.646-1.501v-0.003c-1.375,0.301-2.732-0.584-3.027-1.957c-0.297-1.381,0.584-2.739,1.963-3.032
			c3.373-0.718,7.029,0.327,9.504,3.07c2.482,2.749,3.145,6.486,2.084,9.771c-0.434,1.342-1.871,2.077-3.213,1.646
			c-1.342-0.435-2.076-1.876-1.643-3.214L69.855,37.646L69.855,37.646z M84.732,42.449c0,0.004,0,0.01,0,0.012
			c-0.506,1.555-2.176,2.408-3.734,1.904c-1.562-0.505-2.416-2.174-1.91-3.735c1.547-4.791,0.566-10.254-3.047-14.259
			c-3.619-4.005-8.949-5.537-13.875-4.489c-1.602,0.342-3.178-0.682-3.52-2.28c-0.347-1.604,0.68-3.181,2.278-3.52h0.005
			c6.926-1.473,14.428,0.677,19.512,6.313C85.531,28.03,86.904,35.71,84.732,42.449z" />
		<path d="M37.799,43.295c-12.818,1.27-22.543,9.121-21.711,17.535c0.836,8.42,11.902,14.213,24.723,12.949
			c12.822-1.268,22.539-9.121,21.71-17.541C61.689,47.824,50.621,42.026,37.799,43.295z M49.049,63.148
			c-2.615,5.916-10.145,9.074-16.529,7.016c-6.164-1.991-8.771-8.079-6.072-13.562c2.648-5.375,9.545-8.414,15.646-6.828
			C48.406,51.404,51.629,57.364,49.049,63.148z"/>
	</g>
</g>

</svg>
					</a>
          </li>
				
				<li>
					<a href="https://www.flickr.com/photos/avenirzheng/albums/" target="_blank" title="Flickr">
						<svg aria-hidden="true" viewBox="0 0 94 94" fill="var(--textLink)">
<g>
	<path d="M94,89c0,2.761-2.238,5-5,5H5c-2.762,0-5-2.239-5-5V5c0-2.761,2.238-5,5-5h84c2.762,0,5,2.239,5,5V89z M68.52,28.309
		c-9.781,0-17.711,8.269-17.711,18.468c0,10.202,7.93,18.469,17.711,18.469c9.78,0,17.711-8.268,17.711-18.469
		C86.229,36.578,78.3,28.309,68.52,28.309z M24.985,28.309c-9.508,0-17.215,8.369-17.215,18.692s7.707,18.69,17.215,18.69
		C34.491,65.691,42.2,57.323,42.2,47S34.491,28.309,24.985,28.309z"/>
</g>

</svg>

					
					</a>
				</li>
				
				<li>
					<a href="https://www.facebook.com/avenirzheng" target="_blank" title="Facebook">
						<svg aria-hidden="true" viewBox="0 0 94 94"fill="var(--textLink)">
<g>
	<path d="M89,0H5C2.239,0,0,2.239,0,5v84c0,2.761,2.239,5,5,5h84c2.762,0,5-2.239,5-5V5C94,2.239,91.762,0,89,0z M66.93,21.364
		l-7.226,0.003c-5.664,0-6.761,2.692-6.761,6.643v8.711h13.511L66.45,50.365H52.943v35.012H38.852V50.365H27.07V36.721h11.782
		V26.659c0-11.677,7.133-18.036,17.548-18.036L66.93,8.64V21.364z" />
</g>

</svg>
					</a>
				</li>
				
				
				<li>
					<a href="https://www.twitter.com/AvenirZheng" target="_blank" title="Twitter">
						<svg aria-hidden="true" viewBox="0 0 94 94"fill="var(--textLink)">
<g>
	<path d="M89,0H5C2.239,0,0,2.239,0,5v84c0,2.761,2.239,5,5,5h84c2.762,0,5-2.239,5-5V5C94,2.239,91.762,0,89,0z M85.874,23.302
		c-2.122,3.177-4.752,5.922-7.817,8.164c0.024,0.616,0.036,1.233,0.036,1.855c0,22.315-16.979,45.396-45.396,45.396
		c-8.687,0-17.144-2.479-24.458-7.169c-0.152-0.097-0.217-0.286-0.155-0.456c0.061-0.169,0.234-0.273,0.409-0.254
		c1.229,0.146,2.482,0.219,3.729,0.219c6.758,0,13.156-2.089,18.567-6.053c-6.487-0.547-12.062-4.938-14.068-11.199
		c-0.041-0.131-0.011-0.271,0.08-0.375c0.09-0.103,0.229-0.146,0.362-0.123c1.797,0.342,3.624,0.362,5.387,0.078
		c-6.69-2.08-11.393-8.346-11.393-15.481l0.002-0.208c0.003-0.136,0.077-0.26,0.193-0.326c0.118-0.068,0.264-0.069,0.381-0.003
		c1.743,0.968,3.677,1.591,5.655,1.828c-3.844-3.064-6.102-7.709-6.102-12.678c0-2.867,0.76-5.686,2.194-8.148
		c0.064-0.109,0.178-0.182,0.305-0.19c0.127-0.013,0.25,0.042,0.329,0.142c7.87,9.651,19.444,15.595,31.821,16.358
		c-0.208-1.04-0.312-2.11-0.312-3.191c0-8.936,7.271-16.205,16.206-16.205c4.396,0,8.639,1.806,11.681,4.96
		c3.432-0.699,6.697-1.96,9.715-3.75c0.139-0.082,0.312-0.069,0.438,0.03c0.125,0.101,0.176,0.268,0.126,0.421
		c-1.021,3.199-3.018,5.989-5.696,8.004c2.523-0.439,4.974-1.182,7.302-2.214c0.157-0.072,0.342-0.027,0.453,0.104
		C85.959,22.97,85.971,23.158,85.874,23.302z" />
</g>

</svg>
					</a>
				</li>
				
				<li>
					<a href="https://www.linkedin.com/in/avenrizheng" target="_blank" title="Linkedin" >
						<svg aria-hidden="true" viewBox="0 0 94 94" fill="var(--textLink)">
<g>
	<path d="M89,0H5C2.239,0,0,2.239,0,5v84c0,2.761,2.239,5,5,5h84c2.762,0,5-2.239,5-5V5C94,2.239,91.762,0,89,0z M26.414,84.201
		H10.966V34.503h15.447L26.414,84.201L26.414,84.201z M18.693,27.71c-4.951,0-8.959-4.013-8.959-8.958
		c0.001-4.938,4.01-8.952,8.959-8.952c4.939,0,8.951,4.012,8.951,8.952C27.644,23.697,23.632,27.71,18.693,27.71z M84.266,84.2
		H68.832V60.033c0-5.764-0.105-13.178-8.029-13.178c-8.033,0-9.268,6.278-9.268,12.759V84.2H36.099V34.503h14.816v6.79h0.207
		c2.063-3.907,7.103-8.027,14.614-8.027c15.641,0,18.529,10.294,18.529,23.673V84.2L84.266,84.2z" />
</g>

</svg>
					</a>
          </li>
			</IconList>
    
  </Box>
)

export default SayHi
